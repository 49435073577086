<template>
  <v-card class="mb-8" v-if="requestData">
    <v-card-title>{{'ec.data.delivery_type'| t}}</v-card-title>

    <v-card-text>
      <v-radio-group
        @change="deliveryTypeChange"
        row
        v-model="requestData.delivery_type"
      >
        <div
          v-for="deliveryType in deliveryTypes"
          :key="deliveryType.value"
          class="mr-4 mb-2"
        >
          <v-radio
            :label="deliveryType.name"
            :value="deliveryType.value"
          ></v-radio>

          <div class="font-weight-light grey--text body-2 ml-8 mb-4">
            <!-- 運費說明 -->
            <div>
              <div v-if="deliveryType.fee">{{'ec.data.delivery_fee'| t}}: {{$helper.amount(deliveryType.fee)}}</div>
              <div v-if="deliveryType.fee && deliveryType.fee != deliveryType.outlyingIslandFee">
                {{'ec.data.delivery_outlying_island_fee'|t}} {{ $helper.amount(deliveryType.outlyingIslandFee) }}
              </div>
            </div>

            <div
              v-if="deliveryType.description"
              v-html="nl2br(deliveryType.description)"
            ></div>
          </div>
        </div>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    isHomeDeliveryType: Boolean,
    deliveryTypes: Array,
    deliveryConfig: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Object,
    },
  },
  data: () => ({
    requestData: null,
  }),
  mounted() {
    this.loadDeliveryTypes()
  },
  methods: {
    async loadDeliveryTypes() {
      try {
        const types = await this.$api.collection.ecApi.deliveryTypes()
        this.$emit('setDeliveryTypes', types)
      } catch (error) {
        console.warn(error)
      }
    },
    deliveryTypeChange() {
      let deliveryInfo = window.eagleLodash.cloneDeep(this.requestData.delivery_info)
      if(this.isCvs) {
        deliveryInfo.zip = null
        deliveryInfo.city = null
        deliveryInfo.district = null
        deliveryInfo.address = null
        deliveryInfo.store_no = null
        deliveryInfo.store_name = null
        deliveryInfo.store_address = null
      }

      if(this.isHomeDeliveryType) {
        deliveryInfo.store_no = null
        deliveryInfo.store_name = null
        deliveryInfo.store_address = null
      }

      if(this.isFaceToFace) {
        deliveryInfo.zip = null
        deliveryInfo.city = null
        deliveryInfo.district = null
        deliveryInfo.address = null
        deliveryInfo.store_no = null
        deliveryInfo.store_name = null
        deliveryInfo.store_address = null
      }

      this.$set(this.requestData, 'delivery_info', deliveryInfo)
    },
  },
  computed: {
    isFaceToFace() {
      if(!this.deliveryConfig) return false
      return this.deliveryConfig.isFaceToFace === true
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.requestData = window.eagleLodash.cloneDeep(this.value)
      },
    },
    requestData: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.requestData, this.value)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.requestData))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
